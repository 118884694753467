import {
  LoaderFunctionArgs,
  type MetaFunction,
  redirect,
} from "@remix-run/node";
import { json, useLoaderData } from "@remix-run/react";
import qs from "qs";
import { useTranslation } from "react-i18next";
import V1 from "~/components/Landings/V1";
import V2 from "~/components/Landings/V2";
import contents from "~/constants/contents";
import { getListResources } from "~/services/api";

export async function loader({ params, request }: LoaderFunctionArgs) {
  // return redirect("/services");
  const inUrlQs = {
    limit: 30,
    skip: 0,
    ...qs.parse(request.url.split("?")[1]),
  };

  let promises = [];

  promises.push(
    getListResources({
      token: undefined,
      query: {
        status: "available",
        sort: { popularity: -1 },
        limit: 100,
        skip: 0,
      },
      resource: "services",
    }),
  );

  promises.push(
    getListResources({
      token: undefined,
      query: {
        status: "available",
        limit: 300,
        skip: 0,
      },
      resource: "countries",
    }),
  );

  const [services, countries] = await Promise.all(promises);

  return json({
    services: services.data,
    countries: countries.data,
    inUrlQs,
  });
}

export const meta: MetaFunction = () => {
  const { t } = useTranslation("translation");
  return [
    {
      title: t("title"),
      description: t("description"),
    },
    {
      property: "og:title",
      content: t("og.title"),
    },
    {
      property: "og:description",
      content: t("og.description"),
    },
    {
      property: "og:url",
      content: "https://hidsim.com",
    },
    {
      property: "og:image",
      content: "/img/open_graph_logo.png",
    },
    {
      property: "og:image:width",
      content: "1200",
    },
    {
      property: "og:image:height",
      content: "630",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:site_name",
      content: t("og.site_name"),
    },
    {
      property: "twitter:card",
      content: "summary_large_image",
    },
    {
      property: "twitter:image",
      content: "/img/twitter_graph_logo.png",
    },
  ];
};

function Home() {
  const { inUrlQs } = useLoaderData();

  if (inUrlQs.lv === "2") return <V2 contents={contents} />;
  return <V1 contents={contents} />;
}

export default Home;
