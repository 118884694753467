import { UAContext } from "@quentin-sommer/react-useragent";
import { Link, useLoaderData, useLocation } from "@remix-run/react";
import { useWindowScroll } from "@uidotdev/usehooks";
import cx from "classnames";
import flag from "country-code-emoji";
import { motion } from "framer-motion";
import lodash from "lodash";
import {
  ArrowLeftCircleIcon,
  ChevronRight,
  CircleArrowRight,
} from "lucide-react";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { useInterval } from "usehooks-ts";
import ArrowUpCircleIcon from "~/assets/svg/ArrowUpCircle";
import DismissCircleIcon from "~/assets/svg/DismissCircle";
import LeftArrowIcon from "~/assets/svg/LeftArrow";
import LockClosed from "~/assets/svg/LockClosed";
import NavigationIcon from "~/assets/svg/Navigation";
import RadioButtonIcon from "~/assets/svg/RadioButton";
import SearchIcon from "~/assets/svg/Search";
import SupportIcon from "~/assets/svg/Support";
import VerifyIcon from "~/assets/svg/Verify";
import Accordion from "~/components/Accordion";
import Container from "~/components/Container";
import renderDigits from "~/helpers/renderDigits";
import { LANGUAGES } from "~/i18n";

const initContinueUrl = ({ inUrlQs }) => {
  if (inUrlQs?.sq) return `/services/?text=${inUrlQs.sq}`;
  return `/services`;
};

const NavMobile = ({ t, i18n }) => {
  const { inUrlQs } = useLoaderData<typeof loader>();
  const [{ y }] = useWindowScroll();
  const location = useLocation();
  const [collapsed, setCollapsed] = React.useState(true);

  React.useEffect(() => {
    setCollapsed(true);
  }, [location.hash]);

  return (
    <nav
      className={cx(
        "fixed top-0 z-30 flex w-full flex-col px-6 py-4 lg:hidden transition-all duration-300 ease-in-out",
        {
          "bg-opacity-10 backdrop-blur-3xl": !collapsed || (y && y > 30),
          "bg-opacity-0": !(!collapsed || (y && y > 30)),
        },
      )}
    >
      <motion.div
        initial="closed"
        animate={!collapsed ? "open" : "closed"}
        exit="closed"
        variants={{
          closed: {
            top: "-12rem",
            opacity: 0,
            maxHeight: 0,
          },
          open: {
            top: "-5.4rem",
            opacity: 1,
            maxHeight: 500,
          },
        }}
        transition={{ duration: 0.35, ease: "easeInOut" }}
        className="inset-x-0 z-20 mx-auto flex w-full flex-col items-end justify-start gap-2"
      >
        <div className="mb-5 gap-2 text-neutral-50">
          <div className="flex items-center justify-between p-3">
            <Link
              to="/#try"
              className="text-center text-base font-medium leading-normal"
            >
              {t("landing.v1.nav.try")}
            </Link>
          </div>
          <div className="p-3">
            <Link
              to="/#reviews"
              className="text-center text-base font-medium leading-normal"
            >
              {t("landing.v1.nav.reviews")}
            </Link>
          </div>
          <div className="p-3">
            <Link
              to="/#faq"
              className="text-center text-base font-medium leading-normal"
            >
              {t("landing.v1.nav.faq")}
            </Link>
          </div>
          <div className="p-3">
            <Link
              to="/#start_now"
              className="text-center text-base font-medium leading-normal"
            >
              {t("landing.v1.nav.mobileApp")}
            </Link>
          </div>

          <ul className="mt-2 flex flex-nowrap items-center justify-end gap-3 overflow-x-auto">
            {LANGUAGES.map(({ lng, name, local }) => (
              <li
                key={lng}
                className={cx("btn btn-outline btn-sm", {
                  "btn-active": i18n.language === lng,
                })}
              >
                <a href={`/?lng=${lng}`}>
                  {flag(local)} {name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </motion.div>

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <button
            className="z-20"
            onClick={() => setCollapsed((state) => !state)}
          >
            {collapsed && (
              <NavigationIcon
                className={cx("h-6 w-6 fill-neutral-50", {
                  "rotate-180": i18n.dir() === "ltr",
                })}
              />
            )}
            {!collapsed && <ArrowUpCircleIcon className="h-6 w-6" />}
          </button>
          <span className="mx-1" />
          <img
            src="/img/logo/logo_white_bg_transparent.png"
            className="h-8 w-8"
          />
          <span className="mx-1" />
          <h1 className="text-lg font-extrabold">{t("siteName")}</h1>
        </div>
        <a
          href={initContinueUrl({ inUrlQs })}
          target="_blank"
          className={cx(
            "h-10 px-4 py-2 bg-sky-300 rounded-lg justify-center items-center gap-2 flex transition-all duration-300 ease-in-out",
            {
              "opacity-100": y && y > 560,
              "opacity-0": !(y && y > 560),
            },
          )}
        >
          <div className="text-black text-sm font-semibold font-['Google Sans']">
            {t("landing.v1.nav.continue")}
          </div>
        </a>
      </div>
    </nav>
  );
};

const NavNotMobile = ({ t, i18n }) => {
  const { inUrlQs } = useLoaderData<typeof loader>();
  const [{ y }] = useWindowScroll();
  let cn =
    y > 30
      ? "backdrop-blur-3xl bg-opacity-10 bg-neutral-900"
      : "bg-neutral-900 bg-opacity-0";

  return (
    <nav
      className={`fixed top-0 z-30 w-full flex-col py-3 hidden lg:flex transition-all duration-300 ease-in-out ${cn}`}
    >
      <div className="container mx-auto flex max-w-7xl items-center justify-between px-6">
        <div className="flex items-center gap-4">
          <img
            src="/img/logo/logo_white_bg_transparent.png"
            className="h-8 w-8"
          />
          <h1 className="text-lg font-extrabold">{t("siteName")}</h1>
        </div>
        <div className="flex items-center justify-center gap-4">
          <Link
            to="/#try"
            className="flex items-center justify-center gap-2 rounded-md p-3"
          >
            <div className="font-['Google Sans'] text-center text-base font-medium leading-normal text-neutral-100">
              {t("landing.v1.nav.try")}
            </div>
          </Link>
          <Link
            to="/#reviews"
            className="flex items-center justify-center gap-2 rounded-md p-3"
          >
            <div className="font-['Google Sans'] text-center text-base font-medium leading-normal text-neutral-100">
              {t("landing.v1.nav.reviews")}
            </div>
          </Link>
          <Link
            to="/#faq"
            className="flex items-center justify-center gap-2 rounded-md p-3"
          >
            <div className="font-['Google Sans'] text-center text-base font-medium leading-normal text-neutral-100">
              {t("landing.v1.nav.faq")}
            </div>
          </Link>
          <Link
            to="/#start_now"
            className="flex items-center justify-center gap-2 rounded-md p-3"
          >
            <div className="font-['Google Sans'] text-center text-base font-medium leading-normal text-neutral-100">
              {t("landing.v1.nav.mobileApp")}
            </div>
          </Link>
        </div>

        <section className="flex gap-3 items-center">
          <div className="dropdown dropdown-end">
            <label tabIndex={0} className="btn btn-ghost rounded-btn">
              <span className="text-xl">
                {flag(
                  lodash.find(LANGUAGES, { lng: i18n.language.toLowerCase() })
                    ?.local,
                )}
              </span>{" "}
              {
                lodash.find(LANGUAGES, { lng: i18n.language.toLowerCase() })
                  ?.name
              }
            </label>
            <ul
              tabIndex={0}
              className="menu dropdown-content rounded-box z-[1] mt-4 w-52 bg-base-100 p-2 shadow"
            >
              {LANGUAGES.map(({ lng, name, local }) => (
                <li key={lng}>
                  <a href={`/?lng=${lng}`}>
                    {flag(local)} {name}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <a
            href={initContinueUrl({ inUrlQs })}
            target="_blank"
            className={cx(
              "h-10 px-5 py-2 bg-sky-300 rounded-lg justify-center items-center gap-2 flex transition-all duration-300 ease-in-out",
              {
                "opacity-100": y && y > 560,
                "opacity-0": !(y && y > 560),
              },
            )}
          >
            <div className="text-right text-black text-sm font-bold font-['Google Sans']">
              {t("landing.v1.nav.continue")}
            </div>
          </a>
        </section>
      </div>
    </nav>
  );
};

const Hero = ({ i18n, t, contents }) => {
  const { inUrlQs } = useLoaderData<typeof loader>();
  const { uaResults } = React.useContext(UAContext);
  const [globeLoaded, setGlobeLoaded] = React.useState(false);
  const imgRef = React.useRef<HTMLImageElement>(null);
  const [notification, setNotification] = React.useState(null);
  const [isNotificationVisible, setIsNotificationVisible] =
    React.useState(false);

  useInterval(() => {
    setNotification((prev) => {
      if (!prev) {
        return {
          name: contents.notifications[0].name,
          message: contents.notifications[0].message,
          icon: `https://s3.hidsim.com/services/${contents.notifications[0].name.toLocaleLowerCase()}.svg`,
        };
      } else {
        const cIndex = lodash.findIndex(contents.notifications, {
          name: prev.name,
        });
        let nIndex = cIndex + 1;
        if (nIndex >= contents.notifications.length) nIndex = 0;
        const nNotification = contents.notifications[nIndex];

        return {
          name: nNotification.name,
          message: nNotification.message,
          icon: `https://s3.hidsim.com/services/${nNotification.name.toLocaleLowerCase()}.svg`,
        };
      }
    });
    setIsNotificationVisible(true);
  }, 4000);

  React.useEffect(() => {
    let tid: NodeJS.Timeout;

    if (notification) {
      tid = setTimeout(() => {
        setIsNotificationVisible(false);
      }, 3500);
    }

    return () => {
      clearTimeout(tid);
    };
  }, [notification]);

  function onLoad() {
    setGlobeLoaded(true);
  }

  React.useEffect(() => {
    if (imgRef.current?.complete) onLoad();
  }, []);

  const NOTIFICATIONS = [
    {
      id: 1,
      icon: "/img/landing/telegram.png",
      name: "Telegram",
      message: "Your Telegram code is: 73940",
      className: "top-[20%] right-[10%]",
    },
    {
      id: 2,
      icon: "/img/landing/instagram.png",
      name: "Instagram",
      message: "Use 856 310 to verify your Instagram account.",
      className: "bottom-[40%] right-[30%]",
    },
    {
      id: 3,
      icon: "https://s3.hidsim.com/services/whatsapp.svg",
      name: "WhatsApp",
      message: "Your WhatsApp code: 758490",
      className: "bottom-[10%] left-[20%]",
    },
    {
      id: 4,
      icon: "/img/landing/google.png",
      name: "Google",
      message: "G-719408 is your Google verification code.",
      className: "top-[10%] left-[10%]",
    },
  ];

  return (
    <div className="relative mt-44 flex w-full">
      <motion.div
        initial="closed"
        animate={isNotificationVisible && uaResults.mobile ? "open" : "closed"}
        exit="closed"
        variants={{
          closed: {
            top: "-12rem",
            opacity: 0,
          },
          open: {
            top: "-5.4rem",
            opacity: 1,
          },
        }}
        transition={{ duration: 0.35, ease: "easeInOut" }}
        className="absolute inset-x-0 z-20 mx-auto flex h-20 w-full flex-col items-end justify-start gap-2"
      >
        <div className="flex h-20 items-center justify-start gap-5 self-stretch rounded-2xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-10 px-4 py-3 backdrop-blur-3xl">
          <img className="h-10 w-10 rounded-full" src={notification?.icon} />
          <div className="flex-col items-start justify-start gap-2">
            <div className="flex items-center justify-start gap-1.5 self-stretch">
              <div className="font-['Google Sans'] text-sm font-bold leading-normal text-neutral-100">
                {notification?.name}
              </div>
            </div>
            <div className="flex items-start justify-start gap-2 self-stretch">
              <div className="shrink grow basis-0 text-sm font-normal leading-normal text-neutral-100">
                {notification?.message}
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      <section className="mt-4 flex w-[37rem] max-w-[37rem] flex-col gap-9 md:mt-24 md:gap-12">
        <div className="md:hidden font-['Google Sans'] flex flex-col text-5xl font-bold leading-[3.5rem] text-neutral-100 md:text-7xl">
          <Trans
            t={t}
            i18n={i18n}
            i18nKey="landing.v1.smsVerificationServiceTrans"
            components={[
              <div key="0">SMS</div>,
              <div key="1">Verification</div>,
              <div key="2">Service</div>,
            ]}
          />
        </div>

        <div className="hidden md:block font-['Google Sans'] text-5xl font-bold leading-[4rem] text-neutral-100 md:text-7xl">
          {t("landing.v1.smsVerificationService")}
        </div>

        <div className="font-['Google Sans'] text-xl font-bold leading-7 text-neutral-100">
          {t("landing.v1.receiveSmsOnline")}
        </div>

        <a
          href={initContinueUrl({ inUrlQs })}
          target="_blank"
          className="font-['Google Sans'] z-10 rounded-lg bg-sky-300 bg-opacity-100 px-6 py-3 text-center text-base font-medium leading-normal text-black md:me-auto"
        >
          {t("landing.v1.continueUsingHidSim")}
        </a>

        <div className="hidden flex-col items-start justify-start gap-8 md:flex md:flex-row md:items-center">
          <div className="flex flex-col items-start justify-start gap-2">
            <div className="flex items-center justify-start gap-1">
              <div
                className="font-['Iceland'] text-6xl font-normal leading-10"
                style={
                  {
                    // strokeWidth: 2,
                    // WebkitTextStrokeWidth: 2,
                    // stroke: "currentColor",
                  }
                }
              >
                +95
              </div>
            </div>
            <div className="font-['Roboto'] text-xs font-normal uppercase leading-none tracking-[0.3em] text-neutral-100">
              {t("landing.v1.countries")}
            </div>
          </div>
          <div className="hidden h-10 border border-r-neutral-700 border-opacity-10 opacity-10 md:block" />
          <div className="flex flex-col items-start justify-start gap-2">
            <div className="flex items-center justify-start gap-1">
              <div className="font-['Iceland'] text-6xl font-normal leading-10">
                +500
              </div>
            </div>
            <div className="font-['Roboto'] text-xs font-normal uppercase leading-none tracking-[0.3em] text-neutral-100">
              {t("landing.v1.numbersAvailable")}
            </div>
          </div>
          <div className="hidden h-10 border border-r-neutral-700 border-opacity-10 opacity-10 md:block" />
          <div className="flex flex-col items-start justify-start gap-2">
            <div className="flex items-center justify-start gap-1">
              <div className="font-['Iceland'] text-6xl font-normal leading-10">
                +100
              </div>
            </div>
            <div className="font-['Roboto'] text-xs font-normal uppercase leading-none tracking-[0.3em] text-neutral-100">
              {t("landing.v1.anonymous")}
            </div>
          </div>
        </div>
      </section>
      <section className="absolute start-[40%] top-[5%] md:-top-[10%]">
        <div className="relative">
          <img
            src="/img/landing/globe.png"
            className="max-w-screen-2xl"
            ref={imgRef}
            onLoad={onLoad}
            decoding="async"
          />
          {globeLoaded &&
            NOTIFICATIONS.map((n) => (
              <div
                key={n.id}
                className={`absolute hidden h-20 flex-col items-start justify-start gap-2 md:flex ${n.className}`}
              >
                <div className="flex h-16 flex-col items-start justify-start gap-2 rounded-xl border border-white border-opacity-5 px-3 py-2 backdrop-blur-3xl">
                  <div className="flex items-center justify-start gap-1.5">
                    <img className="h-4 w-4 rounded-full" src={n.icon} />
                    <div className="font-['Google Sans'] text-sm font-bold leading-normal text-white">
                      {n.name}
                    </div>
                  </div>
                  <div className="flex items-start justify-start gap-2">
                    <div className="shrink grow basis-0 text-xs font-normal leading-none text-white">
                      {n.message}
                    </div>
                  </div>
                </div>
                <div className="relative h-2 w-2">
                  <div className="absolute left-0 top-0 h-2 w-2 rounded-full bg-white blur-xl" />
                  <div className="absolute left-0 top-0 h-2 w-2 rounded-full bg-white" />
                </div>
              </div>
            ))}
        </div>
      </section>
    </div>
  );
};

const EasyToUse = ({ i18n, t }) => {
  const { services, countries, inUrlQs } = useLoaderData<typeof loader>();

  const sSettings = {
    // Default parameters
    slidesPerView: 1.2,
    spaceBetween: 15,
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1.2,
        spaceBetween: 15,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 1.2,
        spaceBetween: 15,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 1.2,
        spaceBetween: 15,
      },
      // when window width is >= 640px
      768: {
        slidesPerView: 1.2,
        spaceBetween: 15,
      },
    },
  };

  return (
    <div className="py-28 md:mt-36">
      <Container px={6}>
        <div className="font-['Google Sans'] text-start text-5xl font-bold leading-10 text-neutral-100 md:text-center md:text-7xl">
          {t("landing.v1.easyToUse")}
        </div>

        <div className="mt-10 text-start text-base font-normal leading-normal text-neutral-100 md:text-center">
          {t("landing.v1.receiveSmsMessages")}
        </div>
      </Container>

      <Container px={6} className="relative w-full">
        <div className="absolute -bottom-48 start-0 h-32 w-32 rounded-full bg-sky-300 bg-opacity-50 blur-3xl" />
        <div className="absolute -bottom-96 start-1/3 h-32 w-32 rounded-full bg-sky-300 bg-opacity-50 blur-3xl" />
        <div className="absolute -bottom-48 end-0 h-32 w-32 rounded-full bg-sky-300 bg-opacity-50 blur-3xl" />
      </Container>

      <div className="block md:hidden w-[100vw]">
        <Swiper className="mt-12" centeredSlides {...sSettings}>
          <SwiperSlide className="flex max-h-[25rem] w-80 flex-col overflow-y-hidden rounded-3xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 shadow backdrop-blur-3xl">
            <div className="flex h-32 flex-col items-start justify-start gap-2 self-stretch p-5">
              <div className="font-['Google Sans'] text-xl font-bold leading-7 text-neutral-100">
                {renderDigits({ data: "1. ", i18n })}
                {t("landing.v1.selectService")}
              </div>
              <div className="self-stretch text-base font-normal leading-normal text-neutral-100 text-opacity-70">
                {t("landing.v1.selectServiceDescription")}
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-2 self-stretch px-4">
              <div className="flex h-14 flex-col items-start justify-start gap-3 self-stretch rounded-xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 p-3 backdrop-blur-3xl">
                <div className="flex items-center justify-start gap-2 self-stretch">
                  <img
                    className="h-8 w-8 rounded-full"
                    src="https://s3.hidsim.com/services/tinder.svg"
                  />
                  <div className="font-['Google Sans'] text-sm font-bold leading-normal text-white">
                    Tinder
                  </div>
                </div>
              </div>
              <div className="flex h-14 flex-col items-start justify-start gap-3 self-stretch rounded-xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 p-3 backdrop-blur-3xl">
                <div className="flex items-center justify-start gap-2 self-stretch">
                  <img
                    className="h-8 w-8 rounded-full"
                    src="https://s3.hidsim.com/services/whatsapp.svg"
                  />
                  <div className="font-['Google Sans'] text-sm font-bold leading-normal text-white">
                    WhatsApp
                  </div>
                </div>
              </div>
              <div className="flex h-14 flex-col items-start justify-start gap-3 self-stretch rounded-xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 p-3 backdrop-blur-3xl">
                <div className="flex items-center justify-start gap-2 self-stretch">
                  <img
                    className="h-8 w-8 rounded-full"
                    src="https://s3.hidsim.com/services/openai.svg"
                  />
                  <div className="font-['Google Sans'] text-sm font-bold leading-normal text-white">
                    OpenAI
                  </div>
                </div>
              </div>
              <div className="flex h-14 flex-col items-start justify-start gap-3 self-stretch rounded-xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 p-3 backdrop-blur-3xl">
                <div className="flex items-center justify-start gap-2 self-stretch">
                  <img
                    className="h-8 w-8 rounded-full"
                    src="https://s3.hidsim.com/services/telegram.svg"
                  />
                  <div className="font-['Google Sans'] text-sm font-bold leading-normal text-white">
                    Telegram
                  </div>
                </div>
              </div>
              <div className="flex h-14 flex-col items-start justify-start gap-3 self-stretch rounded-xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 p-3 backdrop-blur-3xl">
                <div className="flex items-center justify-start gap-2 self-stretch">
                  <img
                    className="h-8 w-8 rounded-full"
                    src="https://s3.hidsim.com/services/yahoo.svg"
                  />
                  <div className="font-['Google Sans'] text-sm font-bold leading-normal text-white">
                    Yahoo
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="relative flex max-h-[25rem] w-80 flex-col overflow-y-hidden rounded-3xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 shadow backdrop-blur-3xl">
            <div className="flex h-32 flex-col items-start justify-start gap-2 self-stretch p-5">
              <div className="font-['Google Sans'] text-xl font-bold leading-7 text-neutral-100">
                {renderDigits({ data: "2. ", i18n })}
                {t("landing.v1.selectCountry")}
              </div>
              <div className="self-stretch text-base font-normal leading-normal text-neutral-100 text-opacity-70">
                {t("landing.v1.selectCountryDescription")}
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-2 self-stretch px-4">
              {countries?.results
                .filter((c) =>
                  ["SE", "DE", "ID", "HK", "PT"].includes(c.name.a2),
                )
                .map((c) => (
                  <div
                    key={c.name.a2}
                    className="flex h-14 flex-col items-start justify-start gap-3 self-stretch rounded-xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 p-3 backdrop-blur-3xl"
                  >
                    <div className="flex items-center justify-start gap-2 self-stretch">
                      <div
                        className={`h-8 w-8 rounded-full image-full bg-center bg-cover bg-no-repeat fi-${c.name.a2?.toLowerCase()}`}
                      />
                      <div className="font-['Google Sans'] text-sm font-bold leading-normal text-white capitalize">
                        {c.name.common}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </SwiperSlide>

          <SwiperSlide className="relative inline-flex max-h-[25rem] w-80 flex-col overflow-y-hidden rounded-3xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 shadow backdrop-blur-3xl">
            <div className="flex h-32 flex-col items-start justify-start gap-2 self-stretch p-5">
              <div className="font-['Google Sans'] text-xl font-bold leading-7 text-neutral-100">
                {renderDigits({ data: "3. ", i18n })}
                {t("landing.v1.receiveCode")}
              </div>
              <div className="self-stretch text-base font-normal leading-normal text-neutral-100 text-opacity-70">
                {t("landing.v1.receiveCodeDescription")}
              </div>
            </div>
            <div className="flex h-72 flex-col items-start justify-start gap-2 px-4">
              <div className="flex h-16 flex-col items-end justify-start gap-2 self-stretch">
                <div className="flex h-16 flex-col items-start justify-start gap-2 self-stretch rounded-xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 px-3 py-2 backdrop-blur-3xl">
                  <div className="flex items-center justify-start gap-1.5 self-stretch">
                    <img
                      className="w-4"
                      src="/img/logo/logo_white_bg_transparent.png"
                    />
                    <div className="font-['Google Sans'] text-sm font-bold leading-normal text-white">
                      +1 (472) *** 3481
                    </div>
                  </div>
                  <div className="flex items-start justify-start gap-2 self-stretch">
                    <div className="shrink grow basis-0 text-xs font-normal leading-none text-white">
                      Your Telegram code is: 73940
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex h-16 flex-col items-start justify-start gap-2 self-stretch">
                <div className="flex h-16 flex-col items-start justify-start gap-2 self-stretch rounded-xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 px-3 py-2 backdrop-blur-3xl">
                  <div className="flex items-center justify-start gap-1.5 self-stretch">
                    <img
                      className="w-4"
                      src="/img/logo/logo_white_bg_transparent.png"
                    />
                    <div className="font-['Google Sans'] text-sm font-bold leading-normal text-white">
                      +44 0203 *** 4377
                    </div>
                  </div>
                  <div className="flex items-start justify-start gap-2 self-stretch">
                    <div className="shrink grow basis-0 text-xs font-normal leading-none text-white">
                      Use 856 310 to verify your Instagram account.
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex h-16 flex-col items-start justify-start gap-2 self-stretch">
                <div className="flex h-16 flex-col items-start justify-start gap-2 self-stretch rounded-xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 px-3 py-2 backdrop-blur-3xl">
                  <div className="flex items-center justify-start gap-1.5 self-stretch">
                    <img
                      className="w-4"
                      src="/img/logo/logo_white_bg_transparent.png"
                    />
                    <div className="font-['Google Sans'] text-sm font-bold leading-normal text-white">
                      +49 163 *** 9028
                    </div>
                  </div>
                  <div className="flex items-start justify-start gap-2 self-stretch">
                    <div className="shrink grow basis-0 text-xs font-normal leading-none text-white">
                      G-719408 is your Google verification code.
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex h-16 flex-col items-start justify-start gap-2 self-stretch rounded-xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 px-3 py-2 backdrop-blur-3xl">
                <div className="flex items-center justify-start gap-1.5 self-stretch">
                  <img
                    className="w-4"
                    src="/img/logo/logo_white_bg_transparent.png"
                  />
                  <div className="font-['Google Sans'] text-sm font-bold leading-normal text-white">
                    +62 822 *** 3283
                  </div>
                </div>
                <div className="flex items-start justify-start gap-2 self-stretch">
                  <div className="shrink grow basis-0 text-xs font-normal leading-none text-white">
                    Use 462 415 to verify your WhatsApp account.
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="hidden mx-auto md:flex w-[100vw] justify-center">
        {/* caution: justify center will cause issue on overflow-x */}
        <section className="no-scrollbar mx-auto mt-12 flex gap-3 overflow-x-auto px-5 md:overflow-hidden lg:px-36">
          <div className="flex h-[21rem] min-w-80 max-w-80 flex-col overflow-y-hidden rounded-3xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 shadow backdrop-blur-3xl">
            <div className="flex h-32 flex-col items-start justify-start gap-2 self-stretch p-5">
              <div className="font-['Google Sans'] text-xl font-bold leading-7 text-neutral-100">
                {t("landing.v1.selectService")}
              </div>
              <div className="self-stretch text-base font-normal leading-normal text-neutral-100 text-opacity-70">
                {t("landing.v1.selectServiceDescription")}
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-2 self-stretch px-4">
              <div className="flex h-14 flex-col items-start justify-start gap-3 self-stretch rounded-xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 p-3 backdrop-blur-3xl">
                <div className="flex items-center justify-start gap-2 self-stretch">
                  <img
                    className="h-8 w-8 rounded-full"
                    src="https://s3.hidsim.com/services/telegram.svg"
                  />
                  <div className="font-['Google Sans'] text-sm font-bold leading-normal text-white">
                    Telegram
                  </div>
                </div>
              </div>
              <div className="flex h-14 flex-col items-start justify-start gap-3 self-stretch rounded-xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 p-3 backdrop-blur-3xl">
                <div className="flex items-center justify-start gap-2 self-stretch">
                  <img
                    className="h-8 w-8 rounded-full"
                    src="https://s3.hidsim.com/services/whatsapp.svg"
                  />
                  <div className="font-['Google Sans'] text-sm font-bold leading-normal text-white">
                    WhatsApp
                  </div>
                </div>
              </div>
              <div className="flex h-14 flex-col items-start justify-start gap-3 self-stretch rounded-xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 p-3 backdrop-blur-3xl">
                <div className="flex items-center justify-start gap-2 self-stretch">
                  <img
                    className="h-8 w-8 rounded-full"
                    src="https://s3.hidsim.com/services/tinder.svg"
                  />
                  <div className="font-['Google Sans'] text-sm font-bold leading-normal text-white">
                    Tinder
                  </div>
                </div>
              </div>
              <div className="flex h-14 flex-col items-start justify-start gap-3 self-stretch rounded-xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 p-3 backdrop-blur-3xl">
                <div className="flex items-center justify-start gap-2 self-stretch">
                  <img
                    className="h-8 w-8 rounded-full"
                    src="https://s3.hidsim.com/services/yahoo.svg"
                  />
                  <div className="font-['Google Sans'] text-sm font-bold leading-normal text-white">
                    yahoo
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="relative flex h-[21rem] min-w-80 max-w-80 flex-col overflow-y-hidden rounded-3xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 shadow backdrop-blur-3xl">
            <div className="flex flex-col items-start justify-start gap-2 self-stretch p-5">
              <div className="font-['Google Sans'] text-xl font-bold leading-7 text-neutral-100">
                {t("landing.v1.selectCountry")}
              </div>
              <div className="self-stretch text-base font-normal leading-normal text-neutral-100 text-opacity-70">
                {t("landing.v1.selectCountryDescription")}
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-2 self-stretch px-4">
              {countries?.results
                .filter((c) => ["US", "DE", "ID", "HK"].includes(c.name.a2))
                .map((c) => (
                  <div
                    key={c.name.a2}
                    className="flex h-14 flex-col items-start justify-start gap-3 self-stretch rounded-xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 p-3 backdrop-blur-3xl"
                  >
                    <div className="flex items-center justify-start gap-2 self-stretch">
                      <div
                        className={`h-8 w-8 rounded-full image-full bg-center bg-cover bg-no-repeat fi-${c.name.a2?.toLowerCase()}`}
                      />
                      <div className="font-['Google Sans'] text-sm font-bold leading-normal text-white">
                        {c.name.common}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="relative flex h-[21rem] min-w-80 max-w-80 flex-col overflow-y-hidden rounded-3xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 shadow backdrop-blur-3xl">
            <div className="flex h-32 flex-col items-start justify-start gap-2 self-stretch p-5">
              <div className="font-['Google Sans'] text-xl font-bold leading-7 text-neutral-100">
                {t("landing.v1.receiveCode")}
              </div>
              <div className="self-stretch text-base font-normal leading-normal text-neutral-100 text-opacity-70">
                {t("landing.v1.receiveCodeDescription")}
              </div>
            </div>
            <div className="flex h-72 flex-col items-start justify-start gap-2 px-4">
              <div className="flex h-16 flex-col items-end justify-start gap-2 self-stretch">
                <div className="flex h-16 flex-col items-start justify-start gap-2 self-stretch rounded-xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 px-3 py-2 backdrop-blur-3xl">
                  <div className="flex items-center justify-start gap-1.5 self-stretch">
                    <img
                      className="w-4"
                      src="/img/logo/logo_white_bg_transparent.png"
                    />
                    <div className="font-['Google Sans'] text-sm font-bold leading-normal text-white">
                      +1 (472) *** 3481
                    </div>
                  </div>
                  <div className="flex items-start justify-start gap-2 self-stretch">
                    <div className="shrink grow basis-0 text-xs font-normal leading-none text-white">
                      Your Telegram code is: 73940
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex h-16 flex-col items-start justify-start gap-2 self-stretch">
                <div className="flex h-16 flex-col items-start justify-start gap-2 self-stretch rounded-xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 px-3 py-2 backdrop-blur-3xl">
                  <div className="flex items-center justify-start gap-1.5 self-stretch">
                    <img
                      className="w-4"
                      src="/img/logo/logo_white_bg_transparent.png"
                    />
                    <div className="font-['Google Sans'] text-sm font-bold leading-normal text-white">
                      +44 0203 *** 4377
                    </div>
                  </div>
                  <div className="flex items-start justify-start gap-2 self-stretch">
                    <div className="shrink grow basis-0 text-xs font-normal leading-none text-white">
                      Use 856 310 to verify your Instagram account.
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex h-16 flex-col items-start justify-start gap-2 self-stretch">
                <div className="flex h-16 flex-col items-start justify-start gap-2 self-stretch rounded-xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 px-3 py-2 backdrop-blur-3xl">
                  <div className="flex items-center justify-start gap-1.5 self-stretch">
                    <img
                      className="w-4"
                      src="/img/logo/logo_white_bg_transparent.png"
                    />
                    <div className="font-['Google Sans'] text-sm font-bold leading-normal text-white">
                      +49 163 *** 9028
                    </div>
                  </div>
                  <div className="flex items-start justify-start gap-2 self-stretch">
                    <div className="shrink grow basis-0 text-xs font-normal leading-none text-white">
                      G-719408 is your Google verification code.
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex h-16 flex-col items-start justify-start gap-2 self-stretch rounded-xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 px-3 py-2 backdrop-blur-3xl">
                <div className="flex items-center justify-start gap-1.5 self-stretch">
                  <img
                    className="w-4"
                    src="/img/logo/logo_white_bg_transparent.png"
                  />
                  <div className="font-['Google Sans'] text-sm font-bold leading-normal text-white">
                    +49 624 *** 2264
                  </div>
                </div>
                <div className="flex items-start justify-start gap-2 self-stretch">
                  <div className="shrink grow basis-0 text-xs font-normal leading-none text-white">
                    Your WhatsApp code: 953290
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

const Try = ({ i18n, t }) => {
  const { services, countries, inUrlQs } = useLoaderData<typeof loader>();
  const [sQuery, setSQuery] = React.useState(inUrlQs.sq || "");
  const [cQuery, setCQuery] = React.useState(inUrlQs.cq || "");
  const [step, setStep] = React.useState(1);
  const [selectedService, setSelectedService] = React.useState(null);
  const [selectedCountry, setSelectedCountry] = React.useState(null);

  return (
    <section className="grid grid-cols-1 gap-12 py-12 md:py-36 xl:grid-cols-2">
      <section className="flex flex-col gap-10">
        <div className="flex flex-col gap-10">
          <div className="font-['Google Sans'] text-start text-4xl font-bold text-neutral-100 md:pe-14 md:text-7xl md:leading-[5.5rem]">
            {t("landing.v1.tryHidSimNow")}
          </div>
          <div className="text-neutral-100r text-start font-normal leading-normal">
            {t("landing.v1.browseThroughNumbers")}
          </div>
        </div>
      </section>

      <div className="relative mx-auto flex h-[34rem] w-full flex-col rounded-3xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 shadow backdrop-blur-3xl md:ms-auto md:w-[90%] lg:w-[90%]">
        <section className="top-0 z-10 w-full rounded-t-3xl bg-neutral-900 bg-opacity-[0.98] backdrop-blur-3xl">
          <div className="font-['Google Sans'] px-7 pb-1 pt-5 text-lg font-bold leading-normal text-neutral-100">
            {step === 1 && (
              <span>
                {renderDigits({ data: step, i18n })}.{" "}
                {t("landing.v1.selectService")}
              </span>
            )}
            {step === 2 && (
              <span>
                {renderDigits({ data: step, i18n })}.{" "}
                {t("landing.v1.selectCountry")}
              </span>
            )}
            {step === 3 && (
              <span>
                {renderDigits({ data: step, i18n })}.{" "}
                {t("landing.v1.orderSummary")}
              </span>
            )}
          </div>
          <div className="flex items-center justify-start self-stretch px-5 pb-4 pt-3">
            <div className="flex h-12 shrink grow basis-0 items-center justify-start gap-2 rounded-full border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 p-3 shadow">
              <SearchIcon className="relative h-6 w-6" />
              <div className="relative flex h-6 shrink grow basis-0 items-center justify-end gap-1 px-1">
                <input
                  type="text"
                  placeholder={
                    step === 1
                      ? t("landing.v1.searchAService")
                      : t("landing.v1.searchACountry")
                  }
                  className="disabled:opacity-50 shrink-0 grow basis-0 items-center rounded-xl bg-transparent p-2 px-1 text-sm font-normal leading-normal text-neutral-500 focus:outline-none"
                  onChange={(e) => {
                    if (step === 1) setSQuery(e.target.value);
                    if (step === 2) setCQuery(e.target.value);
                  }}
                  value={step === 1 ? sQuery : cQuery}
                  disabled={step === 3}
                />
                {(sQuery || cQuery) && (
                  <button
                    onClick={() => {
                      if (step === 1) setSQuery("");
                      if (step === 2) setCQuery("");
                    }}
                    className="absolute right-2 flex h-5 w-5 rotate-45 items-center justify-center text-center text-3xl leading-none text-neutral-500"
                  >
                    +
                  </button>
                )}
              </div>
            </div>
          </div>

          {selectedService && (
            <div className="flex w-full items-center justify-start gap-4 bg-neutral-100 bg-opacity-5 px-5">
              <div className="flex shrink grow basis-0 flex-col items-start justify-center gap-2">
                <div className="flex items-center justify-start self-stretch pt-3">
                  <div
                    className={cx(
                      "shrink grow basis-0 text-xs font-normal uppercase leading-none text-neutral-500",
                      {
                        "tracking-widest": i18n.dir() === "ltr",
                      },
                    )}
                  >
                    {t("landing.v1.numberFor")}:
                  </div>
                </div>
                <div className="my-2 flex items-center justify-start gap-2 self-stretch pb-3 pr-5">
                  <img
                    className="h-8 w-8 rounded-full"
                    src={`https://s3.hidsim.com/services/${selectedService.name.a2}.svg`}
                  />
                  <div className="font-['Google Sans'] shrink grow basis-0 text-sm font-bold leading-normal text-neutral-100">
                    {selectedService?.name.common}
                  </div>
                </div>
              </div>
              <DismissCircleIcon
                className="relative h-6 w-6"
                onClick={() => {
                  setSelectedService(null);
                  setSelectedCountry(null);
                  setStep(1);
                }}
              />
            </div>
          )}

          {selectedService && selectedCountry && (
            <div className="h-1 w-full border-b border-neutral-300 border-opacity-5 bg-neutral-100 bg-opacity-5" />
          )}
          {selectedCountry && (
            <div className="flex w-full items-center justify-start gap-4 bg-neutral-100 bg-opacity-5 px-5">
              <div className="flex shrink grow basis-0 flex-col items-start justify-center gap-2">
                <div className="flex items-center justify-start self-stretch pt-3">
                  <div
                    className={cx(
                      "shrink grow basis-0 text-xs font-normal uppercase leading-none text-neutral-500",
                      {
                        "tracking-widest": i18n.dir() === "ltr",
                      },
                    )}
                  >
                    {t("landing.v1.country")}:
                  </div>
                </div>
                <div className="my-2 flex items-center justify-start gap-2 self-stretch pb-3 pr-5">
                  <div
                    className={`h-8 w-8 rounded-full image-full bg-center bg-cover bg-no-repeat fi-${selectedCountry.name.a2?.toLowerCase()}`}
                  />
                  <div className="font-['Google Sans'] shrink grow basis-0 text-sm font-bold leading-normal text-neutral-100">
                    {selectedCountry?.name.common}
                  </div>
                </div>
              </div>
              <DismissCircleIcon
                className="relative h-6 w-6"
                onClick={() => {
                  setSelectedCountry(null);
                  setStep(2);
                }}
              />
            </div>
          )}
        </section>
        <section className="no-scrollbar relative flex w-full flex-col overflow-y-hidden lg:overflow-y-auto">
          {step === 1 &&
            services?.results
              .filter(
                (s) =>
                  s.name.common.toLowerCase().indexOf(sQuery.toLowerCase()) !==
                  -1,
              )
              .map((s) => (
                <button
                  className="text-star flex items-center justify-start self-stretch ps-5"
                  onClick={() => {
                    setSQuery("");
                    setSelectedService(s);
                    setStep(2);
                  }}
                  key={s._id}
                >
                  <div className="flex h-14 shrink grow basis-0 items-center justify-start gap-2 py-4 pr-5">
                    <img
                      className="h-8 w-8 rounded-full"
                      src={`https://s3.hidsim.com/services/${s.name.a2}.svg`}
                    />
                    <div className="font-['Google Sans'] flex h-14 shrink grow basis-0 items-center border-b border-neutral-300 border-opacity-5 pe-5 text-sm font-bold leading-normal text-neutral-100">
                      {s.name.common}
                    </div>
                  </div>
                </button>
              ))}

          {step === 2 &&
            countries?.results
              .filter(
                (c) =>
                  c.name.common.toLowerCase().indexOf(cQuery.toLowerCase()) !==
                  -1,
              )
              .map((c) => (
                <button
                  className="flex items-center justify-between self-stretch px-5 text-start"
                  onClick={() => {
                    setCQuery("");
                    setSelectedCountry(c);
                    setStep(3);
                  }}
                  key={c._id}
                >
                  <div className="flex h-14 shrink grow basis-0 items-center justify-start gap-2 py-4">
                    <div
                      className={`h-8 w-8 rounded-full image-full bg-center bg-cover bg-no-repeat fi-${c.name.a2?.toLowerCase()}`}
                    />
                    <div className="font-['Google Sans'] flex h-14 shrink grow basis-0 items-center border-b border-neutral-300 border-opacity-5 pe-5 text-sm font-bold leading-normal text-neutral-100">
                      {c.name.common}
                    </div>
                  </div>
                  <div className="flex h-14 items-center gap-2 border-b border-neutral-300 border-opacity-5">
                    {/* <div className="flex items-center justify-start gap-1">
                      <div className="font-['Roboto'] text-xs font-normal leading-none text-neutral-500">
                        From
                      </div>
                      <div className="font-['Google Sans'] text-sm font-bold leading-normal text-neutral-100">
                        $0.5
                      </div>
                    </div> */}

                    <RadioButtonIcon className="h-6 w-6" />
                  </div>
                </button>
              ))}

          {step === 3 && (
            <div className="flex h-32 flex-col items-start justify-center gap-4 p-5">
              <div className="font-['Google Sans'] self-stretch text-base font-bold leading-normal text-neutral-100">
                {t("landing.v1.yourNumberIsReady")}
              </div>
              <div className="self-stretch text-base font-normal leading-normal text-neutral-100">
                {t("landing.v1.signUpOrLoginToStartUsingNumber")}
              </div>
            </div>
          )}
        </section>

        {step === 3 && (
          <a
            href={`/services/${selectedService._id}`}
            target="_blank"
            className="transition-all bottom-0 mt-auto flex h-16 w-full items-center hover:gap-4 justify-center gap-3 justify-self-end rounded-b-3xl bg-sky-300 py-3 pl-6 pr-5"
          >
            <div className=" font-['Google Sans'] text-base font-medium leading-normal text-black">
              {t("landing.v1.loginAndPurchase")}
            </div>
            <LeftArrowIcon
              className={cx("relative h-5 w-5", {
                "rotate-180": i18n.dir() === "ltr",
              })}
            />
          </a>
        )}
      </div>
    </section>
  );
};

const Protected = ({ i18n, t }) => {
  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-6">
        <div className="font-['Google Sans'] text-start text-4xl font-bold text-neutral-100 md:text-center md:text-7xl md:leading-[4rem]">
          {t("landing.v1.confidentialAndProtected")}
        </div>
        <div className="text-start text-base font-normal leading-normal text-neutral-100 md:text-center">
          {t("landing.v1.browseThroughNumbers")}
        </div>
      </div>

      <section className="grid grid-cols-1 gap-7 xl:grid-cols-3">
        <div className="relative flex flex-col gap-3">
          <img
            src="/img/landing/ellipse_protected.png"
            className="absolute -left-1/2 -top-1/2"
          />
          <SupportIcon className="h-12 w-12 text-info" />
          <div className="font-['Google Sans'] text-base font-bold leading-normal text-neutral-100">
            {t("landing.v1.support247")}
          </div>
          <div className="text-white/opacity-60 text-base font-normal leading-normal">
            {t("landing.v1.needAssistance")}
          </div>
        </div>

        <div className="relative flex flex-col gap-3">
          <img
            src="/img/landing/ellipse_protected.png"
            className="absolute -left-1/2 -top-1/2"
          />
          <LockClosed className="h-12 w-12 text-info" />
          <div className="font-['Google Sans'] text-base font-bold leading-normal text-neutral-100">
            {t("landing.v1.secureAndAnonymous")}
          </div>
          <div className="text-white/opacity-60 text-base font-normal leading-normal">
            {t("landing.v1.privacyPriority")}
          </div>
        </div>

        <div className="relative flex flex-col gap-3">
          <img
            src="/img/landing/ellipse_protected.png"
            className="absolute -left-1/2 -top-1/2"
          />
          <VerifyIcon className="h-12 w-12 text-info" />
          <div className="font-['Google Sans'] text-base font-bold leading-normal text-neutral-100">
            {t("landing.v1.onlyPayForReceivedCodes")}
          </div>
          <div className="text-white/opacity-60 text-base font-normal leading-normal">
            {t("landing.v1.payOnlyForCodes")}
          </div>
        </div>
      </section>
    </div>
  );
};

const TrustedPrevBtn = () => {
  const swiper = useSwiper();

  return (
    <div
      className="flex cursor-pointer items-center justify-between gap-2"
      onClick={() => swiper.slidePrev()}
    >
      <ArrowUpCircleIcon className="h-6 w-6 -rotate-90" />
      Prev
    </div>
  );
};

const TrustedNextBtn = () => {
  const swiper = useSwiper();

  return (
    <div
      className="flex cursor-pointer items-center justify-between gap-2"
      onClick={() => swiper.slideNext()}
    >
      Next
      <ArrowUpCircleIcon className="h-6 w-6 rotate-90" />
    </div>
  );
};

const Trusted = ({ i18n, t, data }) => {
  const sSettings = {
    // Default parameters
    slidesPerView: 1.2,
    spaceBetween: 10,
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1.2,
        spaceBetween: 10,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 1.4,
        spaceBetween: 15,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 1.7,
        spaceBetween: 15,
      },
      // when window width is >= 640px
      768: {
        slidesPerView: 2.3,
        spaceBetween: 15,
      },
      1024: {
        slidesPerView: 2.6,
        spaceBetween: 15,
      },
      1280: {
        slidesPerView: 3.4,
        spaceBetween: 15,
      },
      1536: {
        slidesPerView: 4.4,
        spaceBetween: 15,
      },
    },
  };

  return (
    <div
      className="relative flex w-full flex-col gap-6 py-24 md:py-32"
      id="reviews"
    >
      <Container px={6}>
        <div className="flex flex-col gap-6">
          <div className="font-['Google Sans'] text-start text-4xl font-bold text-neutral-100 md:text-center md:text-7xl md:leading-[4rem]">
            Trusted by +1M users worldwide
          </div>
          <div className="text-start text-base font-normal leading-normal text-neutral-100 md:text-center">
            With over 1,000,000 users worldwide, our track record speaks for
            itself. Join our trusted community and experience the reliability
            and security that have made us a top choice for communication
            solutions globally.
          </div>
        </div>
      </Container>
      <div className="absolute right-0 top-[23rem] z-10 h-60 w-[10%] bg-gradient-to-l from-neutral-950 to-transparent md:top-[22rem] md:h-96 md:w-[20%]" />
      <div className="absolute left-0 top-[23rem] z-10 h-96 w-[10%] bg-gradient-to-r from-neutral-950 to-transparent md:top-[23rem] md:w-[20%]" />
      <section className="mt-14">
        <Swiper {...sSettings} centeredSlides autoplay loop>
          {data?.reviews.map((r) => (
            <SwiperSlide>
              <div className="flex h-56 flex-col items-start justify-start gap-3 rounded-2xl border border-neutral-100 border-opacity-5 bg-neutral-100 bg-opacity-5 px-5 py-4 backdrop-blur-3xl">
                <div className="flex items-center justify-start gap-0.5 self-stretch">
                  {[...Array(5)].slice(0, r.starCount).map((i) => (
                    <img
                      src="/img/landing/star.png"
                      className="relative h-4 w-4"
                    />
                  ))}
                </div>
                <div className="flex items-center justify-start gap-1.5 self-stretch">
                  <div className="font-['Google Sans'] text-base font-bold leading-normal text-neutral-100">
                    {r.name}
                  </div>
                </div>
                <div className="shrink grow basis-0 self-stretch text-base font-normal leading-normal text-neutral-100">
                  {r.reviewText}
                </div>
                <div className="font-['Roboto'] text-xs font-normal uppercase leading-none tracking-widest text-neutral-500">
                  From {r.from}
                </div>
              </div>
            </SwiperSlide>
          ))}

          <div className="container z-10 mx-auto mt-12 flex h-8 max-w-60 items-center justify-center gap-8 bg-transparent">
            <TrustedPrevBtn />
            <div className="h-4 w-2 border-r border-neutral-400 border-opacity-80" />
            <TrustedNextBtn />
          </div>
        </Swiper>
      </section>
    </div>
  );
};

const Faq = ({ i18n, t, contents }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-20 pt-22">
      <div className="flex h-12 flex-col items-center justify-center gap-4 self-stretch">
        <div className="text-3xl font-bold leading-10 text-neutral-200">
          {t("landing.v1.faq")}
        </div>
      </div>
      <div className="flex items-start justify-center gap-6">
        <div className="z-10 flex flex-col items-center justify-center gap-3">
          {contents?.faq.map((f) => (
            <Accordion key={t(f.q)} title={t(f.q)} description={t(f.a)} />
          ))}
        </div>
      </div>
    </div>
  );
};

const StartNowNotMobile = ({ i18n, t, config }) => {
  return (
    <div className="relative flex w-full flex-col gap-6">
      <Container px={6}>
        <div className="flex flex-col gap-12">
          <div className="font-['Google Sans'] text-center text-4xl font-bold text-neutral-100 md:text-7xl md:leading-[4rem]">
            {t("landing.v1.startNow")}
          </div>
          <div className="text-center text-base font-normal leading-normal text-neutral-100">
            {t("landing.v1.getStartedWithHidSim")}
          </div>

          <div className="grid grid-cols-3 items-center justify-between gap-7">
            {/* <div className="col-span-1 flex flex-col items-center gap-7">
              <img
                src="/img/landing/samsung_start_now.png"
                className="w-3/4"
              />
              <a
                href={config?.apps.android}
                target="_blank"
                className="flex flex-col items-center justify-start gap-1"
              >
                <div className="text-center text-xs font-normal uppercase leading-none tracking-widest text-neutral-500">
                  Also Available on
                </div>
                <div className="font-['Google Sans'] text-center text-xl font-bold leading-7 text-neutral-100">
                  Android
                </div>
                <img
                  src="/img/landing/googleplay_start_now.png"
                  className="mt-5"
                />
              </a>
            </div> */}
            <div className="col-span-3 flex flex-col items-center gap-7">
              <img src="/img/landing/macbook_start_now.png" />
              <div className="flex flex-col items-center justify-start gap-5">
                <div className="flex items-center justify-center rounded-lg bg-sky-300 px-3 py-1.5 shadow">
                  <div
                    className={cx(
                      "text-center text-xs font-bold uppercase leading-none text-black",
                      {
                        "tracking-widest": i18n.dir() === "ltr",
                      },
                    )}
                  >
                    {t("landing.v1.recommended")}
                  </div>
                </div>
                <div className="font-['Google Sans'] text-center text-4xl font-bold leading-10 text-neutral-100">
                  {t("landing.v1.ourWebApp")}
                </div>
                <a
                  href="/services"
                  target="_blank"
                  className="mt-10 inline-flex h-12 items-center justify-center gap-2 rounded-lg bg-sky-300 px-6 py-3 text-center text-neutral-900"
                >
                  <div className="font-['Google Sans'] text-right text-base font-medium leading-normal text-black">
                    {t("landing.v1.continueWithWebApp")}
                  </div>
                  <CircleArrowRight
                    className={cx("relative h-6 w-6", {
                      "rotate-180": i18n.dir() === "rtl",
                    })}
                  />
                </a>
              </div>
            </div>
            {/* <div className="col-span-1 flex flex-col items-center gap-7">
              <img
                src="/img/landing/iphone_start_now.png"
                className="w-3/4"
              />
              <a
                href={config?.apps.ios}
                target="_blank"
                className="flex flex-col items-center justify-start gap-1"
              >
                <div className="text-center text-xs font-normal uppercase leading-none tracking-widest text-neutral-500">
                  Also Available on
                </div>
                <div className="font-['Google Sans'] text-center text-xl font-bold leading-7 text-neutral-100">
                  iOS
                </div>
                <img
                  src="/img/landing/appstore_start_now.png"
                  className="mt-5"
                />
              </a>
            </div> */}
          </div>
        </div>
      </Container>
    </div>
  );
};

const StartNowMobile = ({ i18n, t }) => {
  return (
    <div className="relative flex w-full flex-col gap-6">
      <Container px={6}>
        <div className="flex flex-col gap-12">
          <div className="font-['Google Sans'] text-center text-4xl font-bold text-neutral-100 md:text-7xl md:leading-[4rem]">
            {t("landing.v1.startNow")}
          </div>
          <div className="text-center text-base font-normal leading-normal text-neutral-100">
            {t("landing.v1.getStartedWithHidSim")}
          </div>

          <div className="flex flex-col items-center gap-7">
            <img src="/img/landing/macbook_start_now.png" />
            <div className="flex flex-col items-center justify-start gap-5">
              <div className="flex items-center justify-center rounded-lg bg-sky-300 px-3 py-1.5 shadow">
                <div className="text-center text-xs font-bold uppercase leading-none tracking-widest text-black">
                  {t("landing.v1.recommended")}
                </div>
              </div>
              <div className="font-['Google Sans'] text-center text-4xl font-bold leading-10 text-neutral-100">
                {t("landing.v1.ourWebApp")}
              </div>
              <a
                href="/services"
                target="_blank"
                className="mt-10 inline-flex h-12 items-center justify-center gap-2 rounded-lg bg-sky-300 px-6 py-3 text-center text-neutral-900"
              >
                <div className="font-['Google Sans'] text-right text-base font-medium leading-normal text-black">
                  {t("landing.v1.continueWithWebApp")}
                </div>
                <ArrowLeftCircleIcon
                  className={cx("relative h-6 w-6", {
                    "rotate-180": i18n.dir() === "ltr",
                  })}
                />
              </a>
            </div>
          </div>

          {/* <div className="flex flex-col gap-5">
            <div className="text-center text-xs font-normal uppercase leading-none tracking-widest text-neutral-500">
              Also Available on
            </div>

            <div className="flex items-center justify-between gap-7">
              <a
                href={config?.apps.ios}
                target="_blank"
                className="flex flex-col gap-3"
              >
                <div className="font-['Google Sans'] text-center text-xl font-bold leading-7 text-neutral-100">
                  iOS
                </div>
                <img src="/img/landing/appstore_start_now.png" />
              </a>
              <a
                href={config?.apps.android}
                target="_blank"
                className="flex flex-col gap-3"
              >
                <div className="font-['Google Sans'] text-center text-xl font-bold leading-7 text-neutral-100">
                  Android
                </div>
                <img src="/img/landing/googleplay_start_now.png" />
              </a>
            </div>
          </div> */}
        </div>
      </Container>
    </div>
  );
};

const Footer = ({ i18n, t, data }) => {
  return (
    <div className="relative min-h-64 bg-neutral-900">
      <Container
        className="flex h-full flex-col justify-between gap-12 pt-12"
        px={6}
      >
        <section className="flex flex-col justify-between items-center text-center">
          <div className="text-lg font-bold flex justify-between gap-2 items-center">
            <img
              src="/img/logo/logo_white_bg_transparent.png"
              className="w-10"
            />
            <h1>{t("siteName")}</h1>
          </div>

          <div className="text-base font-bold leading-normal text-neutral-100 mt-5">
            {t("landing.v1.exploreVirtualNumbers")}
          </div>

          <div className="text-xs font-normal leading-none text-neutral-100 mt-3">
            {t("landing.v1.uniqueNumber")}
          </div>

          <section className="flex flex-col gap-3 mt-8">
            <a
              href="/terms"
              target="_blank"
              className="flex items-center justify-center"
            >
              <div className="text-sm font-normal leading-normal text-neutral-100">
                {t("landing.v1.terms")}
              </div>
              <ChevronRight
                className={cx("h-5 w-5", {
                  "rotate-180": i18n.dir() === "rtl",
                })}
              />
            </a>
            <a
              href="/privacy"
              target="_blank"
              className="flex items-center justify-center"
            >
              <div className="text-sm font-normal leading-normal text-neutral-100">
                {t("landing.v1.privacy")}
              </div>
              <ChevronRight
                className={cx("h-5 w-5", {
                  "rotate-180": i18n.dir() === "rtl",
                })}
              />
            </a>
            <a
              href="https://a.hidsim.com/docs"
              target="_blank"
              className="flex items-center justify-center"
            >
              <div className="text-sm font-normal leading-normal text-neutral-100">
                {t("apiForDevelopers")}
              </div>
              <ChevronRight
                className={cx("h-5 w-5", {
                  "rotate-180": i18n.dir() === "rtl",
                })}
              />
            </a>
            <a
              href="Https://t.me/HidSimSupport"
              target="_blank"
              className="flex items-center justify-center"
            >
              <div className="text-sm font-normal leading-normal text-neutral-100">
                {t("landing.v1.contactSupport")}
              </div>
              <ChevronRight
                className={cx("h-5 w-5", {
                  "rotate-180": i18n.dir() === "rtl",
                })}
              />
            </a>
          </section>
        </section>
        <div className="flex items-center justify-center border-t border-neutral-800 py-4">
          <div className="text-center text-xs font-normal uppercase leading-none tracking-widest text-neutral-500">
            ©{new Date().getFullYear()} HidSim.com All rights reserved •{" "}
            <a href="mailto:info@hidsim.com" target="_blank">
              info@hidsim.com
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

function LandingVersionOne({ contents }) {
  const { i18n, t } = useTranslation();
  const { uaResults } = React.useContext(UAContext);

  return (
    <section className="relative bg-neutral-950" data-theme="dark">
      <img
        className="absolute left-0 top-0 opacity-90"
        src="/img/landing/ellipse.png"
      />
      <img className="absolute left-0 top-0" src="/img/landing/pattern.png" />
      <NavNotMobile i18n={i18n} t={t} />

      {uaResults.mobile && <NavMobile i18n={i18n} t={t} />}

      <div className="container mx-auto mt-24 max-w-7xl px-6 md:mt-20">
        <Hero i18n={i18n} t={t} contents={contents} />
      </div>

      <EasyToUse i18n={i18n} t={t} />

      <div
        className="container mx-auto my-8 max-w-5xl px-6 py-12 md:my-16 md:py-32"
        id="why"
      >
        <Protected i18n={i18n} t={t} />
      </div>

      {/* <Trusted i18n={i18n} t={t} data={data} /> */}
      <section className="relative mb-20">
        <img
          className="absolute left-0 top-0 opacity-90"
          src="/img/landing/pattern_faq_left.png"
        />
        <img
          className="absolute right-0 top-0 opacity-90"
          src="/img/landing/pattern_faq_right.png"
        />

        <Container px={6} id="faq">
          <Faq i18n={i18n} t={t} contents={contents} />
        </Container>
      </section>

      <section className="relative z-10">
        <div
          className="absolute -right-[32rem] -top-1/4 h-full w-full max-w-[86rem] opacity-40 lg:-right-[42rem]"
          style={{
            background:
              "radial-gradient(circle, rgba(110, 201, 247, 0.3) 0%, rgba(0,0,0,0) 40%)",
          }}
        />
        <div
          className="absolute -bottom-1/4 -left-[32rem] h-full w-full max-w-[86rem] opacity-40 lg:-left-[42rem]"
          style={{
            background:
              "radial-gradient(circle, rgba(110, 201, 247, 0.3) 0%, rgba(0,0,0,0) 40%)",
          }}
        />
        <img
          className="absolute left-0 top-0 opacity-90"
          src="/img/landing/pattern_try_left.png"
        />
        <img
          className="absolute right-0 top-0 opacity-90"
          src="/img/landing/pattern_try_right.png"
        />
        <div className="container mx-auto max-w-5xl px-6 md:mt-16" id="try">
          <Try i18n={i18n} t={t} />
        </div>
      </section>

      <section className="relative pb-28 pt-48" id="start_now">
        <div
          className="absolute inset-x-0 -top-1/4 -z-0 mx-auto h-full w-full max-w-[86rem] opacity-90"
          style={{
            background:
              "radial-gradient(circle, rgba(110, 201, 247, 0.3) 0%, rgba(0,0,0,0) 50%)",
          }}
        />
        {!uaResults.mobile && <StartNowNotMobile i18n={i18n} t={t} />}
        {uaResults.mobile && <StartNowMobile i18n={i18n} t={t} />}
      </section>

      <Footer i18n={i18n} t={t} />
    </section>
  );
}

export default LandingVersionOne;
