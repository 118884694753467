const SvgLockClosed = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="#6EC9F7"
      d="M24 4a8 8 0 0 1 8 8v4h3.5a4.5 4.5 0 0 1 4.5 4.5v19a4.5 4.5 0 0 1-4.5 4.5h-23A4.5 4.5 0 0 1 8 39.5v-19a4.5 4.5 0 0 1 4.5-4.5H16v-4a8 8 0 0 1 8-8m11.5 15h-23a1.5 1.5 0 0 0-1.5 1.5v19a1.5 1.5 0 0 0 1.5 1.5h23a1.5 1.5 0 0 0 1.5-1.5v-19a1.5 1.5 0 0 0-1.5-1.5M24 27a3 3 0 1 1 0 6 3 3 0 0 1 0-6m0-20a5 5 0 0 0-5 5v4h10v-4a5 5 0 0 0-5-5"
    />
  </svg>
);
export default SvgLockClosed;
