import * as React from "react";
const SvgRadioButton = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    {...props}
  >
    <circle
      cx={12}
      cy={12}
      r={9}
      fill="#171717"
      stroke="#404040"
      strokeWidth={2}
    />
  </svg>
);
export default SvgRadioButton;
